import React, { useState, useEffect, useRef } from "react";
import API from "../utils/request";
import { COMPAIGN_DELETE_URL } from "../api/URLs";
import { Link } from "react-router-dom";
import iconEdit from "../assets/images/iconEdit.svg";
import { FeedESTDateToString, formatDate } from "../functions/format/dateFormat";
import Pagination from "../container/pagination";
import { BiSortDown, BiSortUp } from 'react-icons/bi'
import iconWarning from "../assets/images/warning-icon.jpg"
import MessageModal from '../container/modal/messageModal';
import MessageModalMain from "../container/modal/MessageModalMain";
import CustomPagination from "../container/CustomPagination";
import { dataRequestTypeList } from "../container/FixedData/fixedData";

const WebFormList = (props) => {
    const {
        setLoading,
        limitList,
        dataForm,
        fetchUserFormList,
        pagination,
        setPagination,
        setSortField,
        setSort,
        sort,
        sortField,
        totalCount,
        requestType,
        setRequestType,
        setLimitList
    } = props;
    const messageModalRef = useRef('rememberMe');
    const [messageModal, setMessageModal] = useState(false)
    const [indexDelete, setIndexDelete] = useState(null)
    const [messageFetch, setMessageFetch] = useState("")
    const [item, setItem] = useState("")
    const [id, setId] = useState("")

    const [sortFields, setSortFields] = useState([
        {
            name: "Submission Date",
            sort: 1,
            sortField: "_id",

        },
        {
            name: "Title",
            sort: 1,
            sortField: "title",

        },
        {
            name: "Request Type",
            sort: 1,
            sortField: "title",

        },
        {
            name: "Status",
            sort: 1,
            sortField: "status"

        },
    ]);

    const handleSort = (item, index) => {

        setSort(item.sort)
        if (item.sort === 1) {
            sortFields[index].sort = -1;
        } else {
            sortFields[index].sort = 1
        }
        setSortField(item.sortField);
        setSortFields([...sortFields])
    }

    // change icon when sort ascending && descending
    const changeIcon = (type) => {
        switch (type) {
            case 1:
                return <BiSortDown />;
            case -1:
                return <BiSortUp />;
            default:
                return null;
        }
    };

    const deleteUser = (e) => {
        API.post(COMPAIGN_DELETE_URL, {
            "campaignId": id
        }).then((res) => {
            if (res.status === 1) {
                messageModalRef.current.showSuccess("The intake webform has been successfully deleted.");
                setMessageModal(false)
                fetchUserFormList()
                return false;
            } else {
                messageModalRef.current.showWarning(res.message);
            }
        }).catch((err) => {
            messageModalRef.current.showWarning(err.message);
            setLoading(false)
        })
    }
    const deleteCampaginMessage = (item, index) => {
        setIndexDelete(index)
        setMessageFetch("Open")
        setItem(item)
        setMessageModal(true)
        setId(item._id)

    };


    useEffect(() => {
        fetchUserFormList()
    }, [sortField, sort, limitList]);

    return (
        <div className="col-md-12">
            <div className="table-responsive w-auto" style={{ minHeight: '250px' }}>
                <table className="table">
                    <thead className="thead-primary">
                        <tr>
                            <th style={{ width: "10%" }}>No.</th>
                            {
                                sortFields.map((field, i) => {
                                    return <th key={i} onClick={() => handleSort(field, i)}>{field.name} {changeIcon(field.sort)}</th>
                                })
                            }
                            <th style={{ width: "10%" }}>Action</th>
                        </tr >
                    </thead>
                    <tbody>
                        {dataForm.map((value, index) => (
                            <tr>
                                <td className="td_radiusLeft" style={{ width: "10%" }}>
                                    {index + 1}
                                </td>
                                <td style={{ width: "20%" }}>
                                    <p>{FeedESTDateToString(value.submitDate)}</p>
                                </td>
                                <td
                                    style={{ width: "40%" }}
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title={
                                        value.requestType === "technical-ticket"
                                            ? value.summaryTitle
                                            : value.title
                                    }
                                >
                                    {value.requestType === "technical-ticket"
                                        ? value.summaryTitle && value.summaryTitle.length > 45
                                            ? value.summaryTitle.slice(0, 45) + '...'
                                            : value.summaryTitle
                                        : value.title && value.title.length > 45
                                            ? value.title.slice(0, 45) + '...'
                                            : value.title}
                                </td>
                                <td style={{ width: "15%" }}>{`${dataRequestTypeList(value?.requestType)}`}</td>
                                <td className="text-capitalize" data-toggle="tooltip " style={{ width: "20%" }} data-placement="top" title={value.status}>{value.status.replace("-", " ")}</td>
                                <td style={{ width: "10%" }}>
                                    <div className='d-flex flex-row align-items-center dropdown customize1' >
                                        <div className="btn dropdown--toggle" type="button" data-bs-toggle="dropdown">
                                            <div className="btn dropdown--toggle" type="button" data-bs-toggle="dropdown" style={{ fontSize: "14px", }}>
                                                <img
                                                    src={iconEdit}
                                                    alt="iconEdit"
                                                    style={{
                                                        width: "18px",
                                                        height: "18px",
                                                        cursor: "pointer",
                                                    }}
                                                />
                                            </div>
                                            <div className="dropdown-menu header-menu customMenu1" style={{ marginTop: '-5px', zIndex: 99, marginLeft: "-95px" }} aria-labelledby="dropdownMenuButton">
                                                <Link
                                                    target="_blank"
                                                    to={`/viewFormIntake?cam=${value._id}`}
                                                    state={value}
                                                    style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }}
                                                    className="dropdown-item">View Form
                                                </Link>
                                                {
                                                    value.status === "pending" || value.status === "in-review" ?
                                                        <div>
                                                            <Link
                                                                to={`/editFormIntake?cam=${value._id}`}
                                                                target="_blank"
                                                                style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }}
                                                                className="dropdown-item">Edit Form
                                                            </Link>
                                                            <Link
                                                                to='#!'
                                                                style={{ cursor: 'pointer', fontSize: '14px', fontWeight: 600 }}
                                                                onClick={() => deleteCampaginMessage(value)}
                                                                className="dropdown-item">Delete Form
                                                            </Link>
                                                        </div> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {totalCount >= 10 && (
                <CustomPagination
                    totalCount={totalCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    limitList={limitList}
                    setLimitList={setLimitList}
                />
            )}
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
            />
            <MessageModal
                icon={iconWarning}
                title="Warning"
                description="Are you sure you want to delete this form?"
                showModal={messageModal}
                setMessageModal={setMessageModal}
                deleteUser={deleteUser}
                indexDelete={indexDelete}
            />
            <MessageModalMain textCentered ref={messageModalRef} />
        </div>

    );
};

export default WebFormList;
