import React, { useState } from 'react';
import CloseIcon from '../assets/images/icons/close-icon.svg'
import { capitalizeFirst } from "../functions/format/stringFormat";
import LanguageComponent from './LanguageComponent';
import { Popover, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const AppendKeyMessageV2 = (props) => {
    const {addKeyMessage, setAddKeyMessage, submits, handleRemoveMessage } = props;
    const { isSubmit, setisSubmit } = submits;

    const handleChannel = (event, i, index) => {
        
        const contents = [...addKeyMessage];

        if (event.target.checked) {
            contents[index]['channel'][i].isCheck = 'yes';
            contents[index].errChannel = '';
            setAddKeyMessage(contents);
        } else {
            contents[index]['channel'][i].isCheck = 'no';
            setAddKeyMessage(contents);
        }
    };

    function handleKeyMessage(event, index) {
        const contents = [...addKeyMessage];
        contents[index].keyMessage = event.target.value;
        contents[index].errMesage = '';
        setAddKeyMessage(contents);
    }

    const content = (text) => {
        return <div>
        <p style={{maxWidth: "500px"}} className='m-0 p-0'>{text}</p>
      </div>
    }

    return (
        <div>
            {addKeyMessage.map((it, index) => (
                <div key={index}>
                    <div style={{ marginTop: '30px' }}>
                        <div className='d-flex flex-row align-items-center justify-content-between' style={{ marginBottom: '4px' }}>

                            <div className='d-flex flex-row align-items-center '>
                                <label htmlFor={it.keyMessage + 1} class="form-label size18700" style={{ marginTop: '6px' }} >Campaign Goal / Key Message*</label>
                                {/* <LanguageComponent
                                    addKeyMessage={addKeyMessage}
                                    setAddKeyMessage={setAddKeyMessage}
                                    index={index}
                                    item={it}
                                    keyLanguage={it.keyLanguage}
                                    setKeyLanguage={(value) => {
                                        it.keyLanguage = value
                                        setAddKeyMessage([...addKeyMessage]);
                                    }}
                                /> */}
                            </div>
                        
                                {index > 0 &&
                                    addKeyMessage.length > 1 ? <label
                                    for="appendKeyMessage" class="form-label size14700 mb-0" style={{ cursor: 'pointer', color: 'red'}}
                                    onClick={handleRemoveMessage}> <img alt="" src={CloseIcon} style={{ width: '15px' }} /> </label>
                                : ''}
                        </div>
                        <textarea
                            className="form-control custom-input"
                            id={it.keyMessage+1}
                            style={{ height: '100px', border: `1px solid ${it.errMesage && isSubmit ? "#DC3545" : "#ced4da"}` }}
                            name={it.keyMessage}
                            onChange={(event) => handleKeyMessage(event, index)}
                            value={it.keyMessage}
                        >
                        </textarea>
                        { it.errMesage && isSubmit && <p className='text-danger size12400 mb-0 mt-2'>{it.errMesage}</p>}
                    </div>
                    <div style={{ marginTop: '12px' }}>
                        <label for="channel" class="form-label size14700" style={{ marginBottom: '15px' }}>Channels (please select all that apply)*</label>
                        {
                            it.channel.length > 0 ? <div className="row gx-0 gy-3 mt-16px-">
                                {
                                    it.channel.map((ch, i) => (
                                        <div class="form-check col-12 col-md-4">
                                            <input
                                                className='input-check'
                                                type="checkbox"
                                                id={index+ch.type+i}
                                                value={index+ch.type+i}
                                                checked={ch.isCheck === 'yes' ? true : false}
                                                onChange={(e)=> handleChannel(e, i, index)}
                                            />
                                            {ch.type === 'sms' ? <Popover content={content(`SMS channel is used only if the donor is not opted into email or has not downloaded the CSL Plasma app.`)} title="">
                                                <label
                                                    // title={`An adhoc campaign is: a survey, direct to donor, center hours change etc.`}
                                                    className='size16700' for="EvergreenId">
                                                    {ch.type === 'sms' ? ch.type.toUpperCase() : ch.type === "app" ? "CSL Plasma App" :  capitalizeFirst(ch.type)}
                                                    <InfoCircleOutlined className='ms-1' style={{ color: "red" }} />
                                                </label>
                                            </Popover> : <label className='size14700' htmlFor={index+ch.type+i} >
                                                {ch.type === 'sms' ? ch.type.toUpperCase() : ch.type === "app" ? "CSL Plasma App" :  capitalizeFirst(ch.type)}
                                            </label>}
                                            {/* <label className='size14700' htmlFor={index+ch.type+i} >
                                                {ch.type === 'sms' ? ch.type.toUpperCase() : ch.type === "app" ? "CSL Plasma App" :  capitalizeFirst(ch.type)}
                                            </label> */}
                                        </div>
                                    ))
                                }
                            </div> : null
                        }
                        { it.errChannel && isSubmit && <p className='text-danger size12400 mb-0 mt-2'>{it.errChannel}</p>}
                    </div>
                </div>
            ))}
        </div>


    )
}
export default AppendKeyMessageV2;