import React, { useEffect, useState } from "react";
import DropupIcon from '../assets/images/icons/dropup-icon.svg'
import DropdownIcon from '../assets/images/icons/dropdown-icon.svg'

const CampaignMandatoriesV2 = (props) => {

    const {
        campaignKPI,
        setCampaginKPI,
        setCampaignOffer,
        setLegalDislaimer,
        validateFields,
        campaignOffer,
        legalDislaimer,
        specifyLegalDeclaimer,
        setSpecifyLegalDeclaimer,
        haveApproved, setHaveApproved,
        isSubmit, campaignObject, setCampaignObject,
    } = props
    const [hasOfferActive, setHasOfferActive] = useState()
    const [legalActive, setLegalActive] = useState('')
    const [searchActive, setSearchActive] = useState(false)
    const [haveApprovedText, sethaveApprovedText] = useState("");
    const [haveApprovedShow, sethaveApprovedShow] = useState(false);


    const handleHasCampaign = (e) => {
        const { value, id } = e.target
        setHasOfferActive(id)
        setCampaignOffer([{
            "type": value,
            "isCheck": id
        }])
    }

    const handleLegalDisclaimer = (item) => {
        const { name, value } = item
        setLegalActive(value)
        setLegalDislaimer([{
            "type": name,
            "isCheck": value
        }])
    }

    const handleHaveApproved = (item) => {
        const { name, value } = item
        sethaveApprovedText(value)
        setHaveApproved({
            "title": "Enter name of approver*",
            "type": name,//Yes
            "isCheck": value, //yes
            "insertName": "",
            "isConfirm": false, // true== checked, false=no check
        });
    }

    const handleIsConfirm = (e) => {
        const isChecked = e.target.checked;
        setHaveApproved({
            ...haveApproved,
            insertName: "",
            isConfirm: isChecked
        })
    }

    useEffect(()=> {
        if(legalDislaimer){
            if(legalDislaimer.length > 0){
                setLegalActive(legalDislaimer[0].isCheck)
                if(legalDislaimer[0].isCheck === 'yes' && haveApproved && haveApproved.isCheck) {
                    sethaveApprovedText(haveApproved.isCheck)
                }
            }
        }
        if(campaignOffer){
            if(campaignOffer.length > 0){
                setHasOfferActive(campaignOffer[0].isCheck)
            }
        }
    }, [legalDislaimer, campaignOffer])

    const dataSearch = [
        {
          name: "Yes",
          value: "yes",
        },
        {
          name: "No",
          value: "no",
        },
      ];

    return (
        <div className="">
            <h2 className='size24700' style={{ marginBottom: '40px' }}>Campaign Mandatories</h2>
            <div id="campaignHaveOffer">
                <label for="campaignHaveOffer" class="form-label size18700" style={{ marginBottom: '16px' }}>Does the campaign have an offer?*</label>
                <div className="d-flex flex-row">
                    <div class="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            onChange={(e) => {
                                setCampaignObject({
                                    ...campaignObject, 
                                    campaignHaveOffer: {
                                        "type": "Yes",
                                        "isCheck": "yes"
                                    }
                                })
                            }}
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={campaignObject && campaignObject.campaignHaveOffer && campaignObject.campaignHaveOffer.isCheck === 'yes' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className='input-check'
                            onChange={(e) => {
                                setCampaignObject({
                                    ...campaignObject, 
                                    campaignHaveOffer: {
                                        "type": "No",
                                        "isCheck": "no"
                                    }
                                })
                            }}
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={campaignObject && campaignObject.campaignHaveOffer && campaignObject.campaignHaveOffer.isCheck === 'no' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            No
                        </label>
                    </div>
                </div>
                {
                    validateFields.campaignHaveOffer && isSubmit && !campaignObject?.campaignHaveOffer ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{validateFields.campaignHaveOffer}</p>
                    ) : ''
                }
               
            </div>
            {campaignObject && campaignObject.campaignHaveOffer && campaignObject.campaignHaveOffer.isCheck === 'yes' ? <>
            <div id="hasCampaign" style={{ marginTop: '40px' }}>
                <label for="hasCampaign" class="form-label size14700" style={{ marginBottom: '16px' }}>Has the campaign offer/budget been approved?*</label>
                <div className="d-flex flex-row">
                    <div class="form-check" style={{ marginRight: '80px' }}>
                        <input
                            className='input-check'
                            onChange={handleHasCampaign}
                            type="checkbox"
                            value="Yes"
                            id="yes"
                            checked={hasOfferActive === 'yes' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            Yes
                        </label>
                    </div>
                    <div class="form-check">
                        <input
                            className='input-check'
                            onChange={handleHasCampaign}
                            type="checkbox"
                            value="No"
                            id="no"
                            checked={hasOfferActive === 'no' ? true : false}
                        />
                        <label className="flexCheckDefault size16700">
                            No
                        </label>
                    </div>
                </div>
                {
                    validateFields.campaignOffer && !hasOfferActive ? (
                        <p className='text-danger size12400 mb-0 mt-2'>{validateFields.campaignOffer}</p>
                    ) : ''
                }
                 {hasOfferActive === 'no' ? <p className='text-danger- size14520 mb-0 mb-0 mt-2'>
                    Budget needs to be approved prior to submitting this request as the request will be automatically declined without budget approval.
                </p> : ""}
            </div>
            {hasOfferActive === 'yes' ? <div id="nameApprover-" style={{ marginTop: '10px' }}>
                <label for="nameApprover" class="form-label size13520700" style={{ marginBottom: '4px' }}>
                    Enter name of approver*
                </label>
                <textarea
                    class={`form-control custom-input ${!campaignObject.nameApprover && isSubmit ? 'is-invalid' : ''}`}
                    id="nameApprover"
                    value={campaignObject.nameApprover}
                    onChange={(e) => {
                        setCampaignObject({
                            ...campaignObject, 
                            nameApprover: e.target.value
                        })
                    }}
                ></textarea>
                <div className="invalid-feedback">{validateFields.nameApprover && validateFields.nameApprover}</div>

            </div> : ""}
            </> : ""}

            <div style={{ marginTop: '24px' }}>
                <label for="campaignKpis" class="form-label size18700" style={{ marginBottom: '4px' }}>
                    Has your disclaimer been approved by legal?*

                </label>
                <div className="row mt-3">
                    <div className="col-12 d-flex flex-row" style={{ position: 'relative' }}>
                        {dataSearch.map((item, index) => (
                            <div class="form-check" style={{ marginRight: '80px' }}>
                                <input
                                    className='input-check'
                                    onChange={() => {
                                        handleHaveApproved(item)
                                        sethaveApprovedShow(false)
                                    }}
                                    type="checkbox"
                                    value={item.value}
                                    id={item.value}
                                    checked={haveApproved.isCheck === item.value ? true : false}
                                />
                                <label className="flexCheckDefault size16700">
                                    {item.name}
                                </label>
                            </div>
                        ))}
                    </div>
                    {
                        !haveApproved.isCheck && isSubmit ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{`Fields is Required*`}</p>
                        ) : ''
                    }
                    {haveApproved && haveApproved.isCheck === 'no' ? <p className='text-danger- size14520 mb-0 mb-0 mt-2'>
                        Legal disclaimer needs to be approved prior to submitting this request as the request will be automatically declined without legal approval.
                    </p> : ""}
                </div>
                {/* New Code */}

                {
                    haveApproved && haveApproved.isCheck === 'yes' ? (
                        <div id="haveApproved" style={{ marginTop: '10px' }}>
                            <label for="haveApproveds" class="form-label size13520700" style={{ marginBottom: '4px' }}>
                                Enter name of approver*

                            </label>
                            <textarea
                                class={`form-control custom-input ${!haveApproved.insertName && isSubmit ? 'is-invalid' : ''}`}
                               
                                id="haveApproveds"
                                value={haveApproved && haveApproved.insertName}
                                onChange={(e) => {
                                    setHaveApproved({
                                        ...haveApproved,
                                        insertName: e.target.value,
                                        isConfirm: false
                                    })
                                }}
                            ></textarea>
                            <div className="invalid-feedback">{validateFields.listPeople && validateFields.listPeople}</div>

                        </div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default CampaignMandatoriesV2;