import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import CalendarIcon from '../../assets/images/icons/calendar-icon.svg'
import { CalendarCSL } from '../../container/calendar/CalendarCSL'
import moment from 'moment';
import LanguageComponent from '../LanguageComponent';
import SelectComponent from '../SelectComponent';
import AppendKeyMessageV2 from '../appendKeyMessageV2';
import CalendarComonent from './CalendarComonent';
import { Popover, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

const CampaignDetailsV2 = (props) => {

    const {
        setCampaignTitle,
        campaignTitle,
        setDateFrom,
        dateFrom,
        setDateTo,
        dateTo,
        campaignGoal,
        setCampaginGoal,
        validateFields,
        dateRangeError,
        dateRangeErrorDis,
        dateRangeErrorEnd,
        addKeyMessage,
        setAddKeyMessage,
        submits,
        campaignObject, setCampaignObject,
        messageModalRef
    } = props

    useEffect(() => {
        document.addEventListener("click", handleClickOutSide, true)
        return () => {
            document.removeEventListener('click', handleClickOutSide);
        };
    }, [])

    const refOne = useRef(null)

    // const handleClickOutSide = (e) => {
    //     if (!refOne.current.contains(e.target)) {
    //         setFromActive(false)
    //         setToActive(false)
    //     }
    // }
    const handleClickOutSide = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            setFromActive(false);
            setToActive(false);
        }
    };

    const [toActive, setToActive] = useState(false)
    const [fromActive, setFromActive] = useState(false)

    const changeDateTo = (date) => {
        setDateTo(date)
    }
    const changeDateFrom = (date) => {
        setDateFrom(date)
    }

    let optionsRequestTypes = [
        {
            label: "Campaign Request",
            value: "campaign-requests"
        },
        {
            label: "Technical Ticket",
            value: "technical-ticket"
        },
    ]
    const [requestType, setRequestType] = useState("")

    function handleClick() {
        addKeyMessage.push({
            keyMessage: '',
            keyLanguage: '',
            channel: [
                { "type": "email", "isCheck": "no" },
                { "type": "app", "isCheck": "no" },
                { "type": "sms", "isCheck": "no" },
            ]
        })
        setAddKeyMessage([...addKeyMessage]);
    }

    const handleRemoveMessage = (index) => {
        addKeyMessage.splice(addKeyMessage.length - 1, 1);
        setAddKeyMessage([...addKeyMessage]);
    }

    let titleSelect = "Select Request Types"

    const content = (text) => {
        return <div>
        <p style={{maxWidth: "500px"}} className='m-0 p-0'>{text}</p>
      </div>
    }

    return (
        <div>
            <div className='d-flex flex-row align-items-center' style={{ marginBottom: "21px" }}>
                <h2 id='requestType' className='size24700 text-wrap-mode pe-3'>Request Types*</h2>
                <SelectComponent
                    titleSelect={titleSelect}
                    options={optionsRequestTypes}
                    keyValue={campaignObject.requestType ? campaignObject.requestType : ""}
                    setKeyValue={(value) => {
                        if(value === "campaign-requests" && campaignObject?.requestType !== "campaign-requests" ) {
                            messageModalRef.current.showNoTitle("Please be advised: Digital campaigns take approximately 2 weeks from approval to deployment.", '', true);
                        }
                        setCampaignObject({...campaignObject, requestType: value})
                    }}
                    errorText={validateFields.requestType && !campaignObject.requestType}
                />
            </div>

            {campaignObject.requestType === "campaign-requests" ? <div className='row gx-0 gy-3 mb-4 mt-2'>
                <div className='col-12 col-md-3'>
                    <div class="form-check">
                        <input
                            className='input-check'
                            type="checkbox"
                            id="newDonorsAdhoc"
                            name="newDonorsAdhoc"
                            value="adhoc"
                            checked={campaignObject.adhocEvergreen && campaignObject.adhocEvergreen && campaignObject.adhocEvergreen.type === "adhoc"}
                            onChange={(value) => {
                                
                                setCampaignObject({
                                    ...campaignObject, adhocEvergreen: {
                                        "title": "Adhoc",
                                        "type": "adhoc",
                                        "isCheck": "yes"
                                    }
                            })
                            }}
                        />
                        
                        <Popover content={content(`An adhoc campaign is: a survey, direct to donor, center hours change etc.`)} title="">
                            <label
                                // title={`An adhoc campaign is: a survey, direct to donor, center hours change etc.`}
                                className='size16700' for="newDonorsAdhoc">
                                Adhoc <InfoCircleOutlined style={{color: "red"}} />
                            </label>
                        </Popover>
                        
                    </div>
                </div>
                <div id='adhocEvergreen' className='col-12 col-md-4 ps-3'>
                    <div class="form-check">
                        <input
                            className='input-check'
                            type="checkbox"
                            id="EvergreenId"
                            name="EvergreenId"
                            value="Applicant Donors"
                            checked={campaignObject.adhocEvergreen && campaignObject.adhocEvergreen.type === "evergreen"}
                            onChange={(value) => {
                                setCampaignObject({
                                    ...campaignObject, adhocEvergreen: {
                                        "title": "Evergreen",
                                        "type": "evergreen",
                                        "isCheck": "yes"
                                    }
                                })
                            }}
                        />
                        <Popover content={content(`An evergreen campaign is always running to the same audience criteria i.e. 
                            New Donor running to 1st time donors over a set period of time until 
                            they make subsequent donations and convert to current donor.`)} title="">
                            <label
                                // title={`An adhoc campaign is: a survey, direct to donor, center hours change etc.`}
                                className='size16700' for="EvergreenId">
                                Evergreen <InfoCircleOutlined style={{color: "red"}} />
                            </label>
                        </Popover>
                    </div>
                </div>
                {validateFields.adhocEvergreen && campaignObject.adhocEvergreen?.isCheck !== "yes" ? <div className="invalid-feedback d-block">{validateFields.adhocEvergreen && validateFields.adhocEvergreen}</div> : ""}
            </div> : ""}

            {campaignObject.requestType === "campaign-requests" ? <h2 className='size24700' style={{ marginBottom: '40px', marginTop: "30px" }}>Campaign Details</h2> : ""}
            <div>
                {campaignObject.requestType === "campaign-requests" ? <div id='campaignTitle' style={{ marginBottom: '27px' }}>
                    <label for="Campaign-Title" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Title*</label>
                    <input type="text" class={`form-control custom-input ${validateFields.campaignTitle && !campaignTitle ? 'is-invalid' : ''}`} id="Campaign-Title" value={campaignTitle} onChange={(e) => setCampaignTitle(e.target.value)} aria-describedby="emailHelp" />
                    <div className="invalid-feedback">{validateFields.campaignTitle && validateFields.campaignTitle}</div>
                </div> : ""}

                {campaignObject.requestType === "technical-ticket" ? <>

                    <div style={{ marginBottom: '27px' }}>
                        <label for="summaryTitle" class="form-label size18700" style={{ marginBottom: '4px' }}>Issue Title*</label>
                     
                        <input type="text" 
                        class={`form-control custom-input ${validateFields.summaryTitle && !campaignObject.summaryTitle ? 'is-invalid' : ''}`} 
                        id="summaryTitle" 
                        value={campaignObject.summaryTitle ? campaignObject.summaryTitle : ""}
                        onChange={(event) => {
                            setCampaignObject({ ...campaignObject, summaryTitle: event.target.value })
                        }}
                        />
                        <div className="invalid-feedback">{validateFields.summaryTitle && validateFields.summaryTitle}</div>
                    </div>
                    <div style={{ marginBottom: '27px' }}>
                        <label for="summaryIssue" class="form-label size18700" style={{ marginBottom: '4px' }}>Summary of issue*</label>
                     
                        <textarea
                            className="form-control custom-input"
                            id={`summaryIssue`}
                            style={{ height: '100px', border: `1px solid ${validateFields.summaryIssue && !campaignObject.summaryIssue ? "#DC3545" : "#ced4da"}` }}
                            name={`summaryIssue`}
                            value={campaignObject.summaryIssue ? campaignObject.summaryIssue : ""}
                            onChange={(event) => {
                                setCampaignObject({ ...campaignObject, summaryIssue: event.target.value })
                            }}
                        >
                        </textarea>
                        <div className="invalid-feedback">{validateFields.summaryIssue && validateFields.summaryIssue}</div>
                    </div>
                    {/* campaignObject, setCampaignObject */}
                    <CalendarComonent 
                        id={"dateDiscoveredDateResolution"}
                        dateFrom={campaignObject.dateDiscovered ? campaignObject.dateDiscovered : null} 
                        setDateFrom={(value) => {
                            setCampaignObject({...campaignObject, dateDiscovered: value})
                        }}
                        dateTo={campaignObject.dateResolution ? campaignObject.dateResolution : null} 
                        setDateTo={(value) => {
                            setCampaignObject({...campaignObject, dateResolution: value})
                        }}
                        dateRangeError={validateFields.dateDiscovered && validateFields.dateDiscovered} 
                        dateRangeErrorEnd={validateFields.dateResolution && validateFields.dateResolution} 
                    />

                </> : ""}
                {/* <div style={{ marginBottom: '40px' }}>
                    <label for="Campaign-Goal" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Goal*</label>
                    <textarea onChange={(e) => setCampaginGoal(e.target.value)} value={campaignGoal} class={`form-control custom-input ${validateFields.campaignGoal && !campaignGoal ? 'is-invalid' : ''}`} id="Campaign-Goal" style={{ height: '100px' }}></textarea>
                    <div className="invalid-feedback">{validateFields.campaignGoal && validateFields.campaignGoal}</div>
                </div> */}
                {campaignObject.requestType === "campaign-requests" ? <div>
                    <div id='AppendKeyMessageV2' className='mb-2'>
                        <AppendKeyMessageV2 addKeyMessage={addKeyMessage} setAddKeyMessage={setAddKeyMessage} submits={submits} handleRemoveMessage={handleRemoveMessage} />
                        {/* <label
                            for="appendKeyMessage" class="form-label size14700" style={{ cursor: 'pointer', color: 'red', marginTop: '17px' }}
                            onClick={handleClick}>
                            +Add Key Message</label> &nbsp; &nbsp; */}
                    </div>
                </div> : ""}
                {campaignObject.requestType === "campaign-requests" ? <div id='dateRangeCampaignDuration'>
                    <label for="exampleInputEmail1" class="form-label size18700" style={{ marginBottom: '4px' }}>Campaign Duration*</label>
                    <div className='d-flex flex-row align-items-center justify-content-between'>
                        <div onClick={() => { setFromActive(!fromActive); setToActive(false); }} className='calendar-box' ref={refOne}>
                            <p>{dateFrom ? (moment(dateFrom).format('MM / DD / YYYY')) : 'Select date'}</p>
                            <img onClick={() => { setFromActive(!fromActive); setToActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                        </div>
                        <div style={{ width: '10px', height: '1px', backgroundColor: 'black', marginLeft: '16px', marginRight: '16px' }}></div>
                        <div onClick={() => { setToActive(!toActive); setFromActive(false); }} className='calendar-box' ref={refOne}>
                            <p>{dateTo ? (moment(dateTo).format('MM / DD / YYYY')) : 'Select date'}</p>
                            <img onClick={() => { setToActive(!toActive); setFromActive(false); }} style={{ position: 'absolute', right: '9px', top: '12px' }} alt="" src={CalendarIcon} />
                        </div>
                    </div>
                    {
                        dateRangeError && (!dateFrom || !dateTo) ? (
                            <p className='text-danger size12400 mb-0 mt-2'>{dateRangeError}</p>
                        ) : ''
                    }
                    <div ref={refOne}>
                        <CalendarCSL float={'left'} activeCalendar={fromActive} setactiveCalendar={setFromActive} changeDate={changeDateFrom} date={dateFrom} maxDate={true} handleActiveCalendar={() => { setFromActive(!fromActive); setToActive(false); }} />
                        <CalendarCSL float={'right'} activeCalendar={toActive} setactiveCalendar={setToActive} changeDate={changeDateTo} date={dateTo} maxDate={true} handleActiveCalendar={() => { setToActive(!toActive); setFromActive(false); }} />
                    </div>
                </div> : ""}
            </div>
        </div>
    )
}

export default CampaignDetailsV2