import React, { useEffect, useState } from "react";
import "../styles/submitedFormList.css";
import "../styles/tables.css";
import API from "../utils/request";
import { COMPAIGN_LIST_URL } from "../api/URLs";
import { formatDateAPI2 } from "../functions/format/dateFormat";
import { dataRequestTypeValue, progressStatus } from "../container/FixedData/fixedData";
import LoadingOverlay1 from "../container/loading/loadingOverlay1";
import WebFormListDraft from "../components/webFormListDraft";
import SearchFilterHeaderDraft from "../components/searchFilterHeaderDraft";

const SumbitedDraftFormList = () => {
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState([]);

    const [pagination, setPagination] = useState(1);
    const [totalCount, setTotalCount] = useState();

    const [searchStatus, setSearchStatus] = useState("Title");

    const [search, setSearch] = useState("");
    const [submissionValue, setSubmissionValue] = useState("");
    const [requestType, setRequestType] = useState("");
    const [beforeDate, setBeforeDate] = useState();
    const [afterDate, setAfterDate] = useState();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const [clear, setclear] = useState("")
    const [sortField, setSortField] = useState("_id");
    const [sort, setSort] = useState(-1);
    const [limitList, setLimitList] = useState(10);
    

    const [dataDontationHistory, setdataDontationHistory] =
        useState(progressStatus);

    // const limitList = 10;

    useEffect(() => {
        fetchUserFormList();
        setclear('')
    }, [pagination, clear]);


    const arrStatus = [];
    dataDontationHistory.forEach((it) => {
        if (it.isCheck) {
            arrStatus.push(it.value);
        }
    });

    const fetchUserFormList = () => {
        setLoading(true);
        var params = {
            searchParams: {
                name: `${searchStatus === "Name" ? search : ""}`,
                email: `${searchStatus === "Email" ? search : ""}`,
                department: "",
                title: `${searchStatus === "Title" ? search : ""}`,
                status: arrStatus.length > 0 ? arrStatus : "",
                isSave: "draft",
                submissionDate: `${submissionValue ? submissionValue.toLowerCase() : ""
                    }`,
                fromDate: `${fromDate
                    ? formatDateAPI2(fromDate)
                    : afterDate
                        ? formatDateAPI2(afterDate)
                        : ""
                    }`,
                toDate: `${toDate
                    ? formatDateAPI2(toDate)
                    : beforeDate
                        ? formatDateAPI2(beforeDate)
                        : ""
                    }`,
                    requestType : requestType ? dataRequestTypeValue(requestType) :"",
            },
            limit: limitList,
            page: pagination,
            sorts: {
                [sortField]: sort
            }
            // sortField: sortField,
            // sort: sort
        };
        if(sortField === "title") {
            params.sorts.summaryTitle = sort
        }
        API.post(COMPAIGN_LIST_URL, params)
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setDataForm(res.data);
                    setTotalCount(res.totalCount);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };


    return (
        <div className="container p-0 mt-5">
             {/* Global LoadingOverlay */}
             {loading ? <LoadingOverlay1 /> : null}
            <h3 className="dashboardH3">Draft Forms List</h3>
            <div className="dashboard mt-5">
                {/* Search & Filter Header */}
                <SearchFilterHeaderDraft
                    setSearchStatus={setSearchStatus}
                    searchStatus={searchStatus}
                    setSearch={setSearch}
                    search={search}
                    fetchUserFormList={fetchUserFormList}
                    setdataDontationHistory={setdataDontationHistory}
                    dataDontationHistory={dataDontationHistory}
                    arrStatus={arrStatus}
                    setBeforeDate={setBeforeDate}
                    setAfterDate={setAfterDate}
                    setFromDate={setFromDate}
                    setToDate={setToDate}
                    beforeDate={beforeDate}
                    afterDate={afterDate}
                    fromDate={fromDate}
                    toDate={toDate}
                    setSubmissionValue={setSubmissionValue}
                    submissionValue={submissionValue}
                    setPagination={setPagination}
                    setclear={setclear}
                    requestType={requestType} 
                    setRequestType={setRequestType}
                />
                {/* Table */}
                <div className="col-12 mt-5">
                    <div className="row">
                        <WebFormListDraft
                            setLoading={setLoading}
                            limitList={limitList}
                            setLimitList={setLimitList}
                            dataForm={dataForm}
                            fetchUserFormList={fetchUserFormList}
                            pagination={pagination}
                            setPagination={setPagination}
                            sort={sort}
                            setSort={setSort}
                            setSortField={setSortField}
                            sortField={sortField}
                            totalCount={totalCount}
                            requestType={requestType} 
                            setRequestType={setRequestType}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SumbitedDraftFormList;
